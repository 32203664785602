/* Your main CSS file */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');

:root {
  --color-background-primary: #F4F4F4;
  --color-background-primary: #fff;
  /* /* --color-background-secondary: #071348;  */
  --color-blue: #2B3467;
  /* --color-background-secondary: #2C3333; */
  --color-background-secondary: #2B3467;


  --color-background-white: #ffffff;

  /* /* --color-text-in-secondary: #BD2B2F */
  --color-text-in-primary: #fff;
--color-text-in-secondary: #DD4A48;
--color-text-in-tertiary: #2B3467;
/* --color-text-in-tertiary: #2C3333; */
  --color-white: #fff;

  --default-padding: 2rem;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --color-background-primary: #202020;
    --color-background-secondary: #2C3333;
    --color-background-white: #1c1c1c;
    --color-text-in-primary: #fff;
    --color-text-in-secondary: #DD4A48;
    --color-text-in-tertiary: #fff;
    --color-white: #fff;
  }
} */

/* dark them colors */
/* :root {
  --color-background-primary: #202020;
  --color-background-secondary: #2C3333;
  --color-background-white: #1c1c1c;
  --color-text-in-primary: #fff;
  --color-text-in-secondary: #DD4A48;
  --color-text-in-tertiary: #fff;
  --color-white: #fff;
  --default-padding: 2rem;
} */

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

html {
  font-size: 62.5%;
  font-family: 'Raleway', sans-serif;
  scroll-behavior: smooth;
}

body {
  background: var(--color-background-primary);
}