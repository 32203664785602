.ant-steps-icon {
  color: var(--color-white) !important;
}

.ant-steps-item-finish .ant-progress-inner,
.ant-steps-item-finish .ant-steps-item-icon,
.ant-steps-item-active .ant-progress-inner,
.ant-steps-item-active .ant-steps-icon,
.ant-steps-item-finish .ant-steps-item-title::after {
  /* background: var(--color-text-in-secondary) !important; */
  background-color: var(--color-background-secondary)!important;
}

.antd-country-phone-input .ant-select{min-width:90px}.antd-country-phone-input .ant-input-group-addon{background-color:inherit}.antd-country-phone-input .ant-input-group-addon .ant-select-focused .ant-select-selector,.antd-country-phone-input .ant-input-group-addon .ant-select-selection-item{color:inherit}

.flex {
  display: flex !important;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.btn-primary {
  background: black !important;
  color: var(--color-text-in-primary);
  border: none !important;
}

.btn-secondary {
  background: var(--color-background-secondary);
  color: var(--color-text-in-primary);
  border: none !important;
}

.btn-primary:hover {
  color: white !important;
  border: none !important;
}

.info-text {
  color: gray;
}

.Toastify__toast-body {
  font-size: 14px !important;
  color: black !important;
}

.custom-btn, 
.custom-btn:hover {
  color: var(--color-text-in-primary) !important;
  background: var(--color-blue) !important;
  border: none;
}

.custom-btn:hover {
  color: var(--color-text-in-primary) !important;
  background: var(--color-blue) !important;
  border: none !important;
}

.custom-btn-ghost:hover,
.custom-btn-ghost {
  border-color: var(--color-blue) !important;
  color: var(--color-blue) !important;
}

.custom-btn-ghost,
.custom-btn,
.custom-btn:hover,
.custom-btn-borderless,
.custom-btn-green,
.custom-btn-green:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.75rem 2rem;
  font-weight: 400;
  gap: .5rem;
  font-size: 1.15rem;
}

.custom-btn-borderless:hover,
.custom-btn-borderless {
  border: none !important;
  color: var(--color-blue);
}

.custom-btn-green,
.custom-btn-green:hover {
   background: #46bb9d !important;
   color: var(--color-text-in-primary) !important;
   border: none !important;
}

.slot-approved {
  background: #FFD93D !important;
  color: var(--color-background-secondary) !important;
  border: var(--color-text-in-secondary) !important;
  font-weight: 500;
}

.slot-booked {
  background: #539165 !important;
  color: var(--color-text-in-primary) !important;
  border: var(--color-text-in-secondary) !important;
  font-weight: 500;
}


 @media screen and (min-width: 480px) {
  .custom-btn-ghost,
  .custom-btn,
  .custom-btn:hover,
  .custom-btn-borderless {
    padding: 2rem 2.5rem;
  }
}

@media screen and (min-width: 768px) {

}


 @media screen and (min-width: 1024px) {

}



/* Style the scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

/* Style the thumb */
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
}

/* Style the track */
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}








.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--color-background-secondary);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
